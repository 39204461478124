/**
 * This contains the functions needed to build the config file for downloading the on-the-cusp excel spreadsheet.
 */
import { currentYear } from '@/functions/utils.js';

export const getCuspCourses = ({courses, currentQuarter, passingThreshold}) => {
    const thisQ = `Q${currentQuarter}`;
    return Object.values(courses)
        .filter(course => course.schoolYear === currentYear)
        .filter(course => typeof course.percentageGrade[thisQ] === 'number')
        .filter(course => (
            course.percentageGrade[thisQ] < passingThreshold &&
            course.percentageGrade[thisQ] >= passingThreshold - 10
        ))
};

const getOnTheCuspList = ({data, passingThreshold, currentQuarter}) => {

    const courses = data.map(student => getCuspCourses({
            courses: student.courses,
            currentQuarter,
            passingThreshold
        }).map(course => ({
            studentNumber: student.studentDistrictId,
            studentName: student._studentName,
            gradeLevel: student.currentGradeLevel,
            cohort: student.cohort,
            schoolTeam: student.schoolTeam,
            courseName: course.courseTitle,
            teacher: course.teacher.lastName,
            gradePercentage: course.percentageGrade[`Q${currentQuarter}`],
            numberOfFs: 0, // placeholder
            ethnicity: student.raceAndEthnicity.race,
            gender: student.gender,
            sped: student.specialEducation ? 'SpEd' : 'Not SpEd',
            ell: student.englishLearner ? 'ELL' : 'Not ELL',
            schoolYear: course.schoolYear,
        }))
    ).flat();

    courses.forEach(course => {
        course.numberOfFs = courses.filter(c => c.studentNumber === course.studentNumber).length;
        delete course.schoolYear;
    });

    return courses;
};

const onTheCuspColumns = [
    {header: 'Student Number', width: 15},
    {header: 'Student Name', width: 25},
    {header: 'Grade Level', width: 10},
    {header: 'Cohort', width: 10},
    {header: 'School Team', width: 20},
    {header: 'Course Name', width: 30},
    {header: 'Teacher', width: 20},
    {header: 'Grade (Percentage)', width: 20},
    {header: 'Number of Fs', width: 15},
    {header: 'Ethnicity', width: 10},
    {header: 'Gender', width: 10},
    {header: 'SpEd', width: 10},
    {header: 'ELL', width: 10 }
];

export const downloadOnTheCusp = ({data, passingThreshold=60, currentQuarter}) => {

    const rows = getOnTheCuspList({data, passingThreshold, currentQuarter});

    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    
    XLSX.utils.book_append_sheet(workbook, worksheet, 'On The Cusp');

    // column headers
    XLSX.utils.sheet_add_aoa(worksheet, [onTheCuspColumns.map(col => col.header)], {origin: 'A1'});

    // column width
    worksheet['!cols'] = onTheCuspColumns.map(col => ({wch: col.width}));

    // create an XLSX file and try to save to onTheCusp.xlsx
    XLSX.writeFile(workbook, 'onTheCusp.xlsx');
};
