/**
 * This file contains a list of training videos and their associated meta-data
 */

export const trainings = [
    {
        key: 'intro and security',
        dateCreated: '2022/09/08',
        title: 'Introduction to the RISE Data Hub',
        description: 'Watch a narrated slideshow which gives a comprehensive introduction to this app. It includes the history and evolution of RISE data tools, as well as important security best practices for using this app correctly.',
        view: 'home',
        link: 'kWTOpwUThVE'
    },
    {
        key: 'security refresher',
        dateCreated: '2022/09/08',
        title: 'Data Security Refresher',
        description: 'Refresh your knowledge of important security best practices for using this app.',
        view: 'home',
        link: 'OTeN0lAvPho'
    },
    {
        key: 'navigating the hub',
        dateCreated: '2022/09/15',
        title: 'Getting Around the RISE Data Hub',
        description: 'Learn how to find your way around the Data Hub, using the home screen and the navigation bar. Also covers all of the features in the navigation bar.',
        view: 'home',
        link: 'gA4oG5rFYsI'
    },
    {
        key: 'student list views',
        dateCreated: '2022/10/05',
        title: 'Switching Student List Views',
        description: 'How to switch views in the Student List, and how to create your own views.',
        view: 'tracker',
        link: 'HHN-b9I46l4'
    },
    {
        key: 'key insights',
        dateCreated: '2022/10/05',
        title: 'Viewing Key Insights',
        description: 'The Key Insights section shows some important aggregated data points on the student body.',
        view: 'tracker',
        link: 'y6BUKuard9s'
    },
    {
        key: 'filters',
        dateCreated: '2022/10/05',
        title: 'Manipulating Filters in the Student Lists',
        description: 'Filters allow you to see a list of students that meet certain criteria. This view shows you how to add, edit, and remove filters.',
        view: 'tracker',
        link: 'Uygr3stEXTY'
    },
];
