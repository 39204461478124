/**
 * Contains configuration for pre-set views student lists (used in Tracker.vue). The standard view is a special case as it is different for each grade.
 */

import { fields } from '@/apps/fields.js';

const getPlanFilter = str => [
    {
        displayName: 'Active Plan', 
        field: 'plans',
        type: 'customEquals',
        displayType: ':',
        value: str
    }
];

const _views = [
    {
        name: 'Standard',
        grades: [9, 10, 11, 12],
        fields: [], // calculated dynamically, depending on grade (see _gradeStandardViews below)
    },
    {
        name: 'G8 Risk & Opportunity',
        filters: [],
        grades: [8],
        fields: [
            '_studentName',
            'studentDistrictId',
            'schoolTeam',
            'middleSchool',
            "g8RiskAndOpportunityMarch.riskAndOpportunity",
            "g8RiskAndOpportunityMay.riskAndOpportunity",
            'g8RiskAndOpportunityMay.ADA',
            'g8RiskAndOpportunityMay.GPA',
            'g8RiskAndOpportunityMay.suspensions',
            'g8RiskAndOpportunityMay.riskFactors',
            'gender',
            'raceAndEthnicity.race',
            'englishLearner',
            'specialEducation',
        ]
    },{
        name: 'G9 Risk & Opportunity',
        filters: [],
        grades: [9],
        fields: [
            '_studentName',
            'studentDistrictId',
            'schoolTeam',
            "g9RiskAndOpportunity.riskAndOpportunity",
            'Q1OnTrackStatus',
            'S1OnTrackStatus',
            'onTrackStatus',
            'ADA',
            'GPA',
            'suspensions',
            'raceAndEthnicity.race',
            'englishLearner',
            'specialEducation',
        ]
    },
    {
      name: 'Summer Bridge',
      filters: [
          {
            displayName: 'Summer Bridge Enrollment', 
            field: 'summerBridgeEnrollment',
            type: 'customIn',
            displayType: ':',
            value: [true]
          },
      ],
      grades: [9],
      fields: [
          '_studentName',
          'studentDistrictId',
          'schoolTeam',
          'summerBridgeEnrollment',
          'Q1OnTrackStatus',
          'S1OnTrackStatus',
          'onTrackStatus',
          'ADA',
          'GPA',
          'suspensions',
          'raceAndEthnicity.race',
          'englishLearner',
          'specialEducation',
      ]
  },
    {
        name: 'Bs or Better',
        filters: [
            {
                displayName: 'GPA', 
                field: 'GPA',
                type: '>=',
                displayType: '≥',
                value: 3
            },
        ],
        grades: [9, 10],
        fields: [
            '_studentName',
            'courses',
            'GPA',
            'creditsEarned',
            'passingCourseCount',
            'gender',
            'raceAndEthnicity.race',
            'englishLearner',
            'specialEducation',
        ]
    },
    {
        name: 'On-the-cusp',
        filters: [
            {
                displayName: 'On-the-cusp', 
                field: '_onTheCusp',
                type: 'customIn',
                displayType: ':',
                value: [true]
            },
        ],
        grades: [9, 10, 11, 12],
        fields: [
            '_studentName',
            'studentDistrictId',
            'schoolTeam',
            '_onTheCusp',
            '_onTheCuspCourses',
            'passingCourseCount', 
            'courses',
            'creditsEarned',
            'ADA',
            'plans',
            'GPA',
            'creditsPredicted',
            'raceAndEthnicity.race',
            'englishLearner',
            'specialEducation',
        ]
    },
    {
        name: 'Climber / Slider',
        filters: [
            {
                displayName: 'Climber / Slider', 
                field: '_climberSlider',
                type: 'customIn',
                displayType: ':',
                value: ['Climber', 'Slider']
            },
        ],
        grades: [9, 10],
        fields: [
            '_studentName',
            'studentDistrictId',
            'schoolTeam',
            '_climberSlider',
            // 'g8RiskAndOpportunity',
            "g9RiskAndOpportunity.riskAndOpportunity",
            'Q1OnTrackStatus',
            'Q2OnTrackStatus',
            'Q3OnTrackStatus',
            'courses',
            '_currentQuarterOnTrackStatus',
            'gender',
            'raceAndEthnicity.race',
            'englishLearner',
            'specialEducation',
        ]
    },
    {
        name: 'Attendance',
        grades: [9],
        fields: [
            '_studentName',
            'studentDistrictId',
            'cohort',
            'schoolTeam',
            'entryDate',
            'g9ADA',
            'ADA',
            'Q1Absences',
            'Q2Absences',
            'recentAbsences',
            'suspensions',
            'raceAndEthnicity.race',
            'englishLearner',
            'specialEducation'
        ]
    },
    {
        name: 'Academic Performance',
        grades: [11, 12],
        fields: [
            '_studentName',
            'studentDistrictId',
            'counselorName',
            'postSecTags',
            'GPA',
            'courses',
            'ADA',
            'creditsEarned',
            'plans',
            'graduationConcerns',
            'SATScores.EBRW',
            'SATScores.Math',
        ]
    },
    {
        name: 'FAFSA',
        grades: [11, 12],
        fields: [
            '_studentName',
            'studentDistrictId',
            'counselorName',
            'postSecTags',
            'plans',
            'intendedMajor',
            '_collegeAppsSubmitted',
            'FSAId',
            'FAFSAStatus',
            'FAFSAStateStatus',
        ]
    },
    {
        name: '4-Year College',
        filters: getPlanFilter('4-Year College'),
        grades: [11, 12],
        fields: [
            'studentDistrictId',
            'counselorName',
            'postSecTags',
            'plans',
            'intendedMajor',
            '_4YearStepsDone',
            'collegeCommitment',
            '_studentName',
            'graduationConcerns',
            '_collegeAppsSubmitted',
            'collegeApps',
            'FSAId',
            'FAFSAStatus',
            'FAFSAStateStatus',
            'navianceCollegeList',
            'studentRequestedRecs',
            'applicationEssay',
            '_transcriptsSent',
            'resume'
        ]
    },
    {
        name: '2-Year College',
        filters: getPlanFilter('2-Year College'),
        grades: [11, 12],
        fields: [
            'studentDistrictId',
            'counselorName',
            'postSecTags',
            'plans',
            'intendedMajor',
            '_studentName',
            'graduationConcerns',
            '_collegeAppsSubmitted',
            'collegeApps',
            'FSAId',
            'FAFSAStatus',
            'FAFSAStateStatus',
            'immunizationForm',
            'navianceCollegeList',
            'studentRequestedRecs',
            'teacherSubmittedRecs',
            'collegeCommitment',
            'postSecondaryRegistration',
            'postSecondaryOrientation',
            '_transcriptsSent',
        ]
    },
    {
        name: 'Military',
        filters: getPlanFilter('Military'),
        grades: [11, 12],
        fields: [
            'studentDistrictId',
            'counselorName',
            'postSecTags',
            'plans',
            '_studentName',
            'graduationConcerns',
            '_collegeAppsSubmitted',
            'collegeApps',
            'ASVAB',
            'militaryBranchCommitment',
            'militaryBranchInterest',
            'militaryRecruiter',
        ]
    },
    {
        name: 'Workforce',
        filters: getPlanFilter('Workforce'),
        grades: [11, 12],
        fields: [
            'studentDistrictId',
            'counselorName',
            'postSecTags',
            'plans',
            'intendedMajor',
            '_studentName',
            'graduationConcerns',
            'collegeApps',
            'FAFSAStatus',
            'FAFSAStateStatus',
            'careerInventory',
            'jobApplications',
            'resume',
            'workPermit'
        ]
    },
    {
        name: 'Trade or Technical',
        filters: getPlanFilter('Trade or Technical'),
        grades: [11, 12],
        fields: [
            'studentDistrictId',
            'counselorName',
            'postSecTags',
            'plans',
            'intendedMajor',
            '_studentName',
            'collegeApps',
            '_collegeAppsSubmitted',
            'FSAId',
            'FAFSAStatus',
            'FAFSAStateStatus',
            'collegeCommitment'
        ]
    },
    {
        name: 'Certification',
        filters: getPlanFilter('Certification'),
        grades: [11, 12],
        fields: [
            '_studentName',
            'studentDistrictId',
            'counselorName',
            'postSecTags',
            'plans',
            'intendedMajor',
            'collegeApps',
            '_collegeAppsSubmitted',
            'FSAId',
            'FAFSAStatus',
            'FAFSAStateStatus',
            'collegeCommitment'
        ]
    },
    {
        name: 'Matriculation',
        filters: [],
        grades: [11, 12],
        fields: [
            '_studentName',
            'studentDistrictId',
            'counselorName',
            'postSecTags',
            'plans',
            'intendedMajor',
            'collegeCommitment',
            '_transcriptsSent',
            'FSAId',
            'FAFSAStatus',
            'FAFSAStateStatus',
            'firstGeneration',
            'immunizationForm',
            'postSecondaryRegistration',
            'postSecondaryOrientation',
            '_collegeAppsSubmitted',
            'employment'    
        ]
    },
    {
        name: 'Juniors',
        filters: [],
        grades: [11],
        fields: [
            '_studentName',
            'counselorName',
            'postSecTags',
            'plans',
            'firstGeneration',
            'alternatePlans',
            'intendedMajor',
            'summerCollegePrepInterest',
            'FAFSAIntention',
            'noFAFSAIntentionExplanation',
            'FSAId'
        ]
    },
];

const _gradeStandardViews = [
    // I don't think this standard view is used for G8 because its standard is the R&O view defined above
    {
        name: 'G8 Risk & Opportunity',
        grades: [8],
        filters: [],
        fields: [
            '_studentName',
            'studentDistrictId',
            'middleSchool',
            // 'g8RiskAndOpportunity',
            "g8RiskAndOpportunityMarch.riskAndOpportunity",
            "g8RiskAndOpportunityMay.riskAndOpportunity",
            'g8RiskAndOpportunityMay.ADA',
            'g8RiskAndOpportunityMay.GPA',
            'g8RiskAndOpportunityMay.suspensions',
            'g8RiskAndOpportunityMay.riskFactors',
            'gender',
            'raceAndEthnicity.race',
            'englishLearner',
            'specialEducation',
        ]
    },
    {
        name: 'Standard',
        grades: [9],
        filters: [],
        fields: [
            '_studentName',
            'studentDistrictId',
            'cohort',
            'entryDate',
            'counselorName',
            'schoolTeam',
            '_currentQuarterOnTrackStatus',
            'onTrackStatus',
            'ADA',
            'passingCourseCount',
            'courses',
            'plans',
            'intendedMajor',
            'creditsPredicted',
            'creditsEarned',
            'raceAndEthnicity.race',
            'englishLearner',
            'specialEducation'
        ]
    },
    {
        name: 'Standard',
        grades: [10],
        filters: [],
        fields: [
            '_studentName',
            'studentDistrictId',
            'cohort',
            'entryDate',
            'counselorName',
            'schoolTeam',
            '_currentQuarterOnTrackStatus',
            'onTrackStatus',
            'ADA',
            'passingCourseCount',
            'courses',
            'plans',
            'intendedMajor',
            'creditsPredicted',
            'creditsEarned',
            'raceAndEthnicity.race',
            'englishLearner',
            'specialEducation'
        ]
    },
    {
        name: 'Standard',
        grades: [11],
        filters: [],
        fields: [
            '_studentName',
            'studentDistrictId',
            'cohort',
            'entryDate',
            'counselorName',
            'schoolTeam',
            'postSecTags',
            '_currentQuarterOnTrackStatus',
            'onTrackStatus',
            'ADA',
            'passingCourseCount',
            'courses',
            'plans',
            'intendedMajor',
            'creditsPredicted',
            'creditsEarned',
            'raceAndEthnicity.race',
            'englishLearner',
            'specialEducation'            
        ]
    },
    {
        name: 'Standard',
        grades: [12],
        filters: [],
        fields: [
            '_studentName',
            'postSecTags',
            'plans',
            'intendedMajor',
            '_collegeAppsSubmitted',
            'collegeApps',
            'FAFSAStateStatus',
            'FAFSAStatus',
            'FSAId',
            'specialEducation',
            'englishLearner',
            'firstGeneration',
            'counselorName',
            'schoolTeam',
            'studentDistrictId'
        ]
    },
];

const processView = element => ({
    ...element,
    fields: element.fields.map(field => fields.find(e => e.key === field).key),
    columnLayout: element.fields.map(field => ({field}))
});

export let defaultViews = _views.map(element => processView(element));
export let gradeStandardViews = _gradeStandardViews.map(element => processView(element));

export const updateViews = () => {
    defaultViews = _views.map(element => processView(element));
    gradeStandardViews = _gradeStandardViews.map(element => processView(element));
};

