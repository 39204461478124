/**
 * This file contains the functions related to setting up and dealing with the global $user object.
 */

import { roles as allRoles } from '@/apps/roles';
import { currentEnvironment } from '@/firebase/environments';

const _hasPower = ({power, roles}) => {
    
    if (!roles) {
        console.warn('User has no role assigned');
        return false;
    }
    if (!Array.isArray(roles)) {
        console.warn('User role field should be an array');
        return false;
    }

    const myRoles = allRoles
        .filter(role => roles.includes(role.key))

    const myPowers = myRoles.map(element => element.powers).flat();

    if (!myRoles.length) {
        console.warn('User has no recognized roles');
    }

    if (myRoles.map(role => role.key).includes('superuser')) return true;
    
    return myPowers.includes(power);
};

const toggleMyTag = ({id, icon, user, fb}) => {
    const newValue = user.preferences.taggedStudents;
    newValue[icon] = newValue[icon].includes(id) ?
        newValue[icon].filter(element => element !== id):
        newValue[icon].concat(id);

    fb.user.updatePreference('taggedStudents', newValue);                
};

const isDemoSchool = str => ['merryville_shs_2701411', 'merryville_sms_2700611', 'merryville_willoughby_2701511'].includes(str);

// trim whitespace from view name
const trimWhiteSpace = ({view}) => {
    view.name = view.name.trim();
    return view;
};

// Deprecated fields: In this object, each key-value pair corresponds to the deprecated field and the new field
const deprecatedFields = {
    collegeAppsSubmitted: '_collegeAppsSubmitted',
    transcriptsSent: '_transcriptsSent',
};

const updateDeprecatedFields = ({viewList, fieldObj=deprecatedFields}) => {
    viewList.forEach(view => {
        view.filters?.forEach(filter => {
            filter.field = fieldObj[filter.field] || filter.field;
        });
        view.columnLayout?.forEach(element => {
            element.field = fieldObj[element.field] || element.field
        });
    });
    return viewList;
};

const processPreferences = ({preferences, firstSchool, schools}) => {

    // parse custom views json
    try {
        preferences.customViews = JSON.parse(preferences.customViews)
            .map(view => trimWhiteSpace({view})); // deal with legacy views saved with trailing whitespace - this created a bug
    } catch {
        preferences.customViews = [];
    }

    preferences.customViews = updateDeprecatedFields({viewList: preferences.customViews});

    // Process Default School for User.
    //check for old keys.
    if (['happyville_shs_444444', 'happyville_sms_555555', 'happyville_willoughby_666666'].includes(preferences.defaultSchool)) {
        preferences.defaultSchool = firstSchool ? firstSchool : 'merryville_shs_2701411';
    }

    // rewrite default school to ensure the app doesn't try to access a demo school in production.
    if (currentEnvironment.key === 'demo' && !isDemoSchool(preferences.defaultSchool)) {
        preferences.defaultSchool = 'merryville_shs_2701411';
    } 

    // if demo school is set to default but we are not on demo, then default to the first school.
    if (['production', 'qa'].some(element => element === currentEnvironment.key) && isDemoSchool(preferences.defaultSchool)) {
        preferences.defaultSchool = firstSchool;
    } 

    // check to make sure default school is in the school list, if not default to first school.
    if (!schools.find(school => school === preferences.defaultSchool)) {
        preferences.defaultSchool = firstSchool;
    }

    return preferences;
};

const getInitialUserPreferences = ({userDoc, schools, fb}) => {

    const firstSchool = schools.find(school => !isDemoSchool(school));

    //TECH DEBT: understand this line of code. What is it trying to do and is it important?
    if (['production', 'qa'].some(element => element === currentEnvironment.key) && !firstSchool) console.error('No production school available');

    const defaultPreferences = {
        colorTheme: 'default',
        //TECH DEBT: why does this matter? why can't it be null or something?
        defaultSchool: ['production', 'qa'].some(element => element === currentEnvironment.key) ?
            firstSchool : 
            'merryville_shs_2701411',
        taggedStudents: {star: [], flag: [], warning: [], help: [], done: []},
        taskTags: [],
        customViews: [],
        customCharts: [],
        defaultView: 'Standard'
    };

    const preferences = processPreferences({preferences: userDoc.preferences, firstSchool, schools});

    return {
        ...defaultPreferences, ...preferences
    };
};

export const getUserObject = ({userDoc, user}) => {
    const schools = userDoc.dataHubSchools;
    const district = userDoc.district;
    const preferences = getInitialUserPreferences({userDoc, schools});
    const defaultSchool = preferences.defaultSchool;
    const displayName = user.displayName || [userDoc.firstName, userDoc.lastName].join(' ');
    const initials = displayName.split(' ').map(e => e[0]).join('').slice(0, 2);
    const roles = userDoc.dataHubRoles || [];
    const hasPower = power => _hasPower({power, roles});
    //I feel like this should be defined somewhere that is more global in the app instead of attaching it to the user object.
    //I could see future filtering done with the user object if there is ever a need for limiting by grade.
    const availableGrades = [8, 9, 10, 11, 12];
    const uid = user.uid;
    const agreeToDataPolicy = userDoc.agreeToDataPolicy || false;

    // return user object which will be accessible globally as this.$user
    return { 
        displayName, uid, roles, preferences, schools, availableGrades,
        district, defaultSchool, initials, agreeToDataPolicy, hasPower, toggleMyTag
    };
};

export const preferences = [
    {
        key: 'colorTheme',
        setFn: 'setColorTheme',
        type: 'category',
        default: 'default',
        options: [
            {
                value: 'default',
                displayName: 'Default theme'
            },
            {
                value: 'access',
                displayName: 'Accessible theme'
            },
            {
                value: 'dark',
                displayName: 'Dark theme'
            },
        ]
    },
    {
        key: 'defaultSchool',
        setFn: null,
        type: 'category',
        default: '',
        options: []
    },
];

export const exportedForTesting = { trimWhiteSpace, updateDeprecatedFields };
