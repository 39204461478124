/**Contains the modal used for adding, editing, and deleting activities, i.e. notes, tasks, and check-ins.*/

<template>
    <Modal 
        class="activity-modal" 
        data-cy="activity-modal" 
        ref="activityModal" 
        :title="`${editMode ? 'Edit' : 'Add'} ${mode.slice(0,mode.length-1)}`" 
        instructions="" 
        :disableSuccess="!formReady"
        :wide="mode==='Check-ins'"
        @cancel="cancelModal" 
        @success="addOrUpdate()"
    >
        <div class="task-title" v-show="mode!=='Check-ins'">
            {{ mode === 'Tasks' ? 'Task Title' : 'Note Content' }}
            <input
                data-cy="add-task-input"
                class="add-task-input"
                type="text"
                placeholder="Type here..."
                v-model="taskTitle"
            >
        </div>
        <StudentSelect :studentsToEdit="studentsToEdit" :mode="mode" @selectionChanged="selectionChanged"/>
        <div class="check-in-details" v-show="mode==='Check-ins'">
            Check-in details
            <div>
                <textarea cols="53" rows="3" v-model="taskTitle" placeholder="Type here" data-cy="check-in-details-input"></textarea>
            </div>
        </div>
        <div class="select-category-area" v-show="mode!=='Check-ins'">
            <div>
                Select categories
            </div>
            <div class="category-list-area" data-cy="category-list-area">
                <span 
                    v-for="(category, index) in categories" :key="index" 
                    class="category-entry clickable"
                >
                    <span 
                        data-cy="category-entry"
                        :style="getCategoryStyle(category)"
                        @click="addRemoveCategory(category)"
                    >
                        {{ selectedCategories.includes(category) ? '&#x2714;&nbsp;': '' }}{{ category.title }}
                    </span>
                </span>
            </div>
        </div>
        <div v-if="mode === 'Check-ins'">
            <table class="multiple-choice-table">
                <tr>
                    <td>
                        <div class="multiple-choice-title">YDF Categories</div>
                        <MultipleChoice :options="YDFCategories" v-model="selectedYDFCategories" formKey="YDF" data-cy="YDF-category"/>
                    </td>
                    <td style="padding-left: 10px;">
                        <div class="multiple-choice-title">Check-in Category</div>
                        <CustomSelect :options="checkInCategories" v-model="selectedCheckInCategory" formKey="checkin" data-cy="check-in-category" />
                    </td>
                </tr>
                <tr>
                    <td>
                        <CustomSelect
                            style="position: relative; top: -10px;"
                            :options="lengths.map(e => ({displayName: e}))"
                            v-model="length"
                            :customWidth="150"
                        />
                    </td>
                    <td>
                        Date
                        <span
                            v-if="!editMode"
                            class="column-select-button add-date-button"
                            data-cy="add-new-check-in-date"
                            @click="addAnotherDate"
                        >
                            <span class="material-icons-outlined column-select-icon">
                                add
                            </span>
                        </span>
                        <span class="check-in-dates-list">
                            <input
                                v-for="(date, index) in dateArr" :key="index"
                                type="date"
                                class="check-in-date-entry"
                                data-cy="check-in-date-entry"
                                v-model="dateArr[index]"
                            >
                        </span>
                    </td>
                </tr>
            </table>
        </div>
        <div v-if="mode === 'Tasks'">
            Due date
            <div>
                <input type="date" name="mydate" id="mydate" v-model="dueDate" data-cy="due-date-input">
            </div>
        </div>
        <div v-if="editMode && mode === 'Tasks'">
            <input type="checkbox" name="taskCompleted" id="taskCompleted" v-model="taskCompleted">
            <label for="taskCompleted">Task completed</label>
        </div>
        <div v-if="editMode">
            <CustomButton 
                class="btn-warning delete-task-button" 
                data-cy="delete-task-button"
                @click="deleteTask" 
                :buttonText="`Delete ${mode.slice(0,mode.length-1)}`" 
            />
        </div>
    </Modal>
</template>

<script>
import { blackOrWhite } from '@/functions/utils.js';
import StudentSelect from '@/components/modals/studentSelect.vue';
import CustomButton from '@/components/customButton.vue';
import CustomSelect from '@/components/customSelect.vue';
import MultipleChoice from '@/components/multipleChoice.vue';
import Modal from '@/components/modals/modal.vue';

const modesAndDocTypes = [
    {mode: 'Tasks', docType: 'task'},
    {mode: 'Notes', docType: 'note'},
    {mode: 'Check-ins', docType: 'check-in'},
];

export default {
    components: { MultipleChoice, CustomButton, CustomSelect, StudentSelect, Modal },
    data() {
        return {
            mode: 'Tasks',
            editMode: false,
            taskTitle: '',
            taskBeingEdited: null,
            studentsToEdit: [],
            selectedStudents: [],
            categories: [],
            selectedCategories: [],
            taskCompleted: false,
            dueDate: '',
            dateArr: [''],
            YDFCategories: ['Positive Relationships', 'Self-Advocacy', 'Self-Regulation', 'Self-Motivation',],
            selectedYDFCategories: '',
            checkInCategories: [
                '1:1 Student Meetings',
                'Parent Communication',
                'Group/Workshop/Lunch Bunch',
                'Student Communication',
                'School/Staff Communication',
                'Announcement'
            ].map(e => ({displayName: e})),
            lengths: [
                '0-5 mins', '5-10 mins', '10-15 mins', '15-20 mins', '20-30 mins', '30-45 mins', '45-60 mins', '60+ mins', 'Attempted'
            ],
            length: '0-5 mins',
            selectedCheckInCategory: '',
        }
    },
    methods: {
        blackOrWhite(color) {
            return blackOrWhite(color);
        },
        addAnotherDate() {
            this.dateArr.push('');
        },
        getDocType() {
            const result = modesAndDocTypes.find(e => e.mode == this.mode)?.docType || 'task';
            return result;
        },
        addRemoveCategory(category) {
            if (this.selectedCategories.includes(category)) {
                this.selectedCategories = this.selectedCategories.filter(e => e !== category);
            } else {
                this.selectedCategories.push(category);
            }
        },
        getCategoryStyle(category) {
            return `background-color: ${category.color}; color: ${this.blackOrWhite(category.color)};`
        },
        launchAdd({mode, student}) {
            this.mode = mode;
            this.categories = this.$user.preferences.taskTags;
            this.editMode = false;
            this.taskTitle = '';
            this.studentsToEdit = student ?
                [{
                    name:`${student.firstName} ${student.lastName}`,
                    id: student.studentRISEId
                }] : 
                [];
            this.selectedCategories = [];
            this.taskCompleted = false;
            this.length = '0-5 mins';
            this.taskBeingEdited = null;
            this.dueDate = '';
            this.dateArr = [''];
            this.selectedYDFCategories = '';
            this.selectedCheckInCategory = '';

            this.$refs.activityModal.show();
        },
        launchEdit(task) {
            this.mode = modesAndDocTypes.find(element => element.docType == task.docType).mode;
            this.editMode = true;
            this.taskTitle = task.title;
            this.studentsToEdit = [...task.studentArr];
            this.taskBeingEdited = task;

            if (task.docType === 'check-in') {
                this.selectedYDFCategories = task.YDFCategories;
                this.selectedCheckInCategory = task.checkInCategory;
                this.dateArr = [task.date];
                this.length = task.length;
            } else {
                this.categories = this.$user.preferences.taskTags;
                this.selectedCategories = this.$user.preferences.taskTags
                    .filter(tag => task.categories.some(element => element.title == tag.title && element.color == tag.color));
            }

            if (task.docType === 'task') {
                this.taskCompleted = task.completed;
                this.dueDate = task.dueDate;
            }

            this.$refs.activityModal.show();
        },
        addOrUpdate() {
            if (this.taskBeingEdited === null) {
                this.add();
            } else {
                this.update(this.taskBeingEdited);
            }
            this.editMode = false;
        }, 
        add() {
            if (this.mode === 'Check-ins') {
                this.addCheckIn();
                return;
            }

            const taskToAdd = {
                docType: this.getDocType(),
                title: this.taskTitle,
                author: this.$user.displayName,
                uid: this.$user.uid,
                studentArr: Array.from(this.selectedStudents),
                createdDatetime: Date.parse(new Date()),
                categories: this.selectedCategories,
            };

            if (this.mode === 'Tasks') {
                taskToAdd.completed = false;
                taskToAdd.dueDate = this.dueDate;
            };

            // add to firestore
            this.fb.messages.add(taskToAdd);            
        },
        addCheckIn() {
            this.dateArr.forEach(dateStr => {
                if (!dateStr) return;
                const taskToAdd = {
                    docType: 'check-in',
                    title: this.taskTitle,
                    author: this.$user.displayName,
                    uid: this.$user.uid,
                    length: this.length,
                    studentArr: Array.from(this.selectedStudents),
                    createdDatetime: Date.parse(new Date()),
                    YDFCategories: this.selectedYDFCategories,
                    checkInCategory: this.selectedCheckInCategory,
                    date: dateStr,
                };

                // add to firestore
                this.fb.messages.add(taskToAdd);    
            });
        },
        update(task) {
            if (this.mode === 'Check-ins') {
                this.updateCheckIn(task);
                return;
            }

            task.title = this.taskTitle;
            task.categories = this.selectedCategories;
            task.studentArr = Array.from(this.selectedStudents);

            if (this.mode === 'Tasks') {
                task.dueDate = this.dueDate;
                task.completed = this.taskCompleted;
            };

            this.fb.messages.update(task.docID, task);
        },
        updateCheckIn(task) {
            task.title = this.taskTitle;
            task.studentArr = Array.from(this.selectedStudents);
            task.YDFCategories = this.selectedYDFCategories;
            task.checkInCategory = this.selectedCheckInCategory;
            task.date = this.dateArr[0];
            task.length = this.length;

            this.fb.messages.update(task.docID, task);
        },
        cancelModal() {
            this.editMode = false;
        },
        selectionChanged(studentArr) {
            this.selectedStudents = studentArr;
        },
        deleteTask() {
            this.fb.messages.delete(this.taskBeingEdited);
            this.$refs.activityModal.hide();
        },
    },
    computed: {
        formReady: function () {
            if (this.mode !== 'Check-ins') return true;
            if (!this.selectedStudents.length) return false;
            if (!this.selectedCheckInCategory.length) return false;
            if (!this.taskTitle) return false;
            if (!this.dateArr[0]) return false;
            return true;
        }
    },
    mounted() {
        this.ee.on('addTask', obj => this.launchAdd(obj));
        this.ee.on('editTask', task => this.launchEdit(task));
    }
}
</script>

<style lang="scss">
    .activity-modal {
        .task-title {
            margin-top: 1.5rem;
        }
        textarea {
            margin: 10px 0;
            border: none;
            resize: none;
        }
        .add-task-input {
            width: 400px;
            border: none;
            height: 30px;
            margin: 1rem 0 0;
        }
        .check-in-dates-area {
            margin-top: 10px;
        }
        .check-in-date-entry, #mydate {
            height: 34px;
            border: none;
            margin-left: 5px;            
            margin-bottom: 10px;
        }
        #mydate {
            margin-top: 10px;
        }
        .add-date-button {
            margin: 0 5px;
            position: relative;
            top: 3px;
        }
        .delete-task-button {
            color: var(--color-bg);
            float: right;
            position: relative;
        }
        .category-list-area {
            margin: 10px 0;
        }
        .multiple-choice-table {
            text-align: left;
            td {
                vertical-align: top;
                .multiple-choice-title {
                    margin-bottom: 5px;
                }
                &:first-child {
                    min-width: 190px;
                }
            }
        }
    }
    .clickable {
        span {
            padding: 5px;
            border-radius: 5px;
        }
        display: inline-block;
        &:hover {
            opacity: 0.5;
            cursor: pointer;
        }
    }    
    


</style>
