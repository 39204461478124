/**Contains the modal component. Modals are hidden when mounted. They have to be shown with `this.$refs.modalName.show()`*/

<template>
    <div class="modal-container">      
        <div :class="dynamicClass" class="scrim">
            <div :class="contentClasses" :style="width ? 'width:' + width + 'px !important;': ''">
                <div class="modal-header">
                    <span @click="hide" class="close" v-if="showXOut">&times;</span>
                    <h2>{{ title }}</h2>
                </div>
                <div class="modal-body">
                    <p v-if="instructions">{{ instructions }}</p>
                    <slot></slot>
                    <div v-if="showButtons">
                        <CustomButton data-cy="modal-success-button" class="btn" :class="disableSuccess ? 'btn-disabled':''" @click="success" :buttonText="acceptButtonText" />
                        <CustomButton v-if="showCancelButton" data-cy="modal-cancel-button" class="btn" @click="cancel" :buttonText="cancelButtonText" />
                    </div>
                    <div v-else class="bottom-padding-area">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomButton from '@/components/customButton.vue';
export default {
    name: 'Modal',
    props: {
        title: String,
        instructions: String,
        wide: {
            type: Boolean,
            required: false,
            default: false
        },
        showButtons: {
            type: Boolean,
            required: false,
            default: true
        },
        disableSuccess: {
            type: Boolean,
            required: false,
            default: false
        },
        showXOut: {
            type: Boolean,
            required: false,
            default: true
        },
        showCancelButton: {
            type: Boolean,
            required: false,
            default: true
        },
        acceptButtonText: {
            type: String,
            required: false,
            default: 'OK'
        },
        cancelButtonText: {
            type: String,
            required: false,
            default: 'Cancel'
        },
        showModal: {
            type: Boolean,
            required: false,
            default: false
        },
        fullscreen: {
            type: Boolean,
            required: false,
            default: false,
        },
        width: {
            type: Number,
            required: false,
        }
    },
    components: {
        CustomButton
    },
    emits: ['success', 'cancel'],
    data() {
        return {
            myValue: '',
            dynamicClass: this.showModal ? 'visible' : 'hidden',
        }
    },
    methods: {
        show() {
            this.dynamicClass = 'visible';
        },
        hide() {
            this.dynamicClass = 'hidden';
        },
        success() {
            if (this.disableSuccess) return;
            this.$emit('success');
            this.dynamicClass = 'hidden';
        },
        cancel() {
            this.$emit('cancel');
            this.dynamicClass = 'hidden';
        },
    },
    computed: {
        contentClasses() {
            let classes = 'modal-content';
            classes += this.wide ? ' modal-content-wide' : '';
            classes += this.fullscreen ? ' fullscreen' : '';
            return classes;
        }
    },
}
</script>

<style lang="scss" scoped>

.btn {
    margin-right: 20px;
    margin-bottom: 20px;
}

.hidden {
  display: none;
}

.visible {
  display: block;
}

.scrim {
    font-family: 'Lato', sans-serif;
    position: fixed; 
    z-index: 30; 
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: var(--color-contrast-full);
    background-color: rgba(0,0,0,0.4);
}

.modal-content-wide {
    width: 510px !important;
}
.fullscreen {
    max-width: 90vw !important;
}

.modal-content {
    position: relative;
    background-color: var(--color-contrast-lower);
    margin: 5vh auto;
    padding: 0;
    border: 1px solid var(--color-contrast-higher);
    width: 450px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    border-radius: 20px;

    .modal-header {
        padding: 10px 16px;
        background-color: var(--color-success);
        color: var(--color-bg);
        border-radius: 20px 20px 0px 0px;
        padding: 16px 20px 10px 20px;
        h2 {
            margin: 0;
        }
    }

    // TECH DEBT - modal should not be setting styling for it's content
    .modal-body {
        .spaced-input {
            width: 400px;
            margin-bottom: 10px;
            border-radius: 5px;
            border-style: none;
            padding: 5px;  
        }
        padding: 0px 20px 0px;
        label {
            padding-left: 10px;
        }
    }
}

.bottom-padding-area {
    margin-bottom: 20px;
}

@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

.close {
    color: var(--color-bg);
    float: right;
    font-size: 2.4rem;
    font-weight: bold;
    &:hover, &:focus {
        color: var(--color-contrast-medium);
        text-decoration: none;
        cursor: pointer;
    }
}

</style>
