/**
    This is the top-level component for the entire app. The component element contains the current view of the app. 
    Global modals (those that can be called from any view) are stored here.
*/
<template>
    <div id="app-container">
        <Navbar />
        <!-- <NotificationBanner message="The 'Number of Applications' field is currently unavailable. We're working on restoring it soon." /> -->
        <div class="app-main-area">
            <EditDataModal />
            <TrainingModal />
            <SecurityModal />
            <IdleModal />
            <ChangeModeModal />
            <ActivitiesModal />
            <PostSecTagManager />
            <FreshSuccessTagManager />

            <router-view v-slot="{ Component }">
                <keep-alive>
                    <component ref="currentRoute" :is="Component" />
                </keep-alive>
            </router-view>
            
        </div>
    </div>
</template>

<script>
import PostSecTagManager from '@/components/modals/postSecTagManager.vue';
import ActivitiesModal from '@/components/modals/activitiesModal.vue';
import EditDataModal from '@/components/modals/editDataModal.vue';
import ChangeModeModal from '@/components/modals/changeModeModal.vue';
import IdleModal from '@/components/modals/idleModal.vue';
import TrainingModal from '@/components/modals/trainingModal.vue';
import SecurityModal from '@/components/modals/securityModal.vue';
import Navbar from '@/components/nav/navbar.vue';
import NotificationBanner from '@/components/notificationBanner.vue';
import FreshSuccessTagManager from '@/components/modals/freshSuccessTagManager.vue';

export default {
    components: {
        Navbar, PostSecTagManager, ActivitiesModal, EditDataModal, ChangeModeModal, IdleModal, TrainingModal, SecurityModal, NotificationBanner, FreshSuccessTagManager
    },
    inject: ["posthog"],
    data() {
        return {
            showBanner: false
        }
    }
}
</script>

<style lang="scss">
    @import 'assets/styles/mystyles.scss';
</style>
