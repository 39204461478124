/**
    Navbar component. Used in top-level App.vue.
*/
<template>
    <ul class="navbar" data-cy="navbar" v-if="!$route.meta.hideNavigation">
        <li title="Data Hub Home">
            <router-link class="rise-home-anchor" to="/home">
                <img data-cy="rise-logo" src="@/assets/img/rise_logo_vertical_sm.png" alt="RISE Logo">
            </router-link>
        </li>
        <li class="navbar-current-school" v-if="shouldShowNavbarLinks()">
            <a
                :class="getChangeModeClass()"
                data-cy="change-school-or-grade"
                @mouseover="showTooltip('Change school or grade')"
                @mouseleave="showTooltip('')"
                @click="ee.emit('launchChangeMode')"
            >
                <span class="current-school" data-cy="school">{{ getSchool() }}</span>
                <span class="current-grade" data-cy="grade">Grade&nbsp;{{ getGrade() }}</span>
                <span class="marking-period" data-cy="marking-period">{{ getMarkingPeriod() }}</span>
            </a>
        </li>
        <li class="navbar-current-applet" data-cy="current-applet" v-if="shouldShowNavbarLinks()">
            <span class="material-icons current-applet-icon">
                {{ this.$router.currentRoute._value.meta.icon }}
            </span>
            <span class="navbar-current-applet-title">
                {{ this.$router.currentRoute._value.name }}
            </span>
            <span class="material-icons applet-dropdown-arrow">
                arrow_drop_down
            </span>
            <AppSelector
                class="navbar-applet-selector"
                :routes="getRoutes()"
                @launchTool="launchTool"
            />
        </li>
        <li
            v-if="trainingsExist()"
            class="navbar-help-area"
            data-cy="navbar-help"
            @mouseover="showTooltip('Show training videos for this page')"
            @mouseleave="showTooltip('')"
            @click="ee.emit('launchHelp')"
            title="Show Training Videos"
        >
            <span class="material-icons navbar-icon">help_outline</span>
        </li>
        <li
            v-if="shouldShowLoginReportIcon()"
            data-cy="navbar-login-analytics-button"
            class="navbar-help-area"
            @click="showLoginAnalytics"
            title="Show Login Reports"
        >
            <span class="material-icons navbar-icon">assessment</span>
        </li>
        <li class="user-account">
            <div 
                data-cy="initials"
                class="avatar initials"
                @mouseover="showTooltip('Logged in as ' + getUserName())"
                @mouseleave="showTooltip('')"
            >
                {{ getInitials() }}

                <ul class="dropdown-area settings-dropdown">
                <li
                    data-cy="open-settings" 
                    v-if="store.state.hasLaunched"
                    class="dropdown-item" 
                    @mouseover="showTooltip('Open view settings')"
                    @mouseleave="showTooltip('')"
                    @click="openSettings"
                >
                    Settings
                </li>
                <a href="https://drive.google.com/drive/folders/18C7dHnGJ8BDaW_eitEvdRxqY5ZVoyapR" target="_blank" rel="noopener noreferrer">
                    <li
                        class="dropdown-item"
                        @mouseover="showTooltip('Go to Tutorial Docs in new tab')"
                        @mouseleave="showTooltip('')"
                        @click=""
                    >
                        Tutorial Docs
                    </li>
                </a>
                <li 
                    data-cy="logout" 
                    class="dropdown-item" 
                    @mouseover="showTooltip('Log out')" 
                    @mouseleave="showTooltip('')"
                    @click="logOut"
                >
                    Logout
                </li>

            </ul>
            </div>
            
        </li>
    </ul>
</template>

<script>
import { routes } from '@/router/index.js';
import { trainings } from '@/apps/trainings.js';
import AppSelector from '@/components/nav/appSelector.vue';

export default {
    components: { AppSelector },
    methods: {
        // navigate to settings page
        openSettings() {
            this.$router.push('/settings');
        },
        logOut() {
            this.session.processLogOut();
        },
        launchTool(route) {
            this.$router.push(route);
        },
        shouldShowLoginReportIcon() {
            return this.$router.currentRoute._value.name === 'Home' && this.$user?.hasPower('viewLoginReports')
        },
        shouldShowNavbarLinks() {
            return !['Home', 'Settings', 'Login Reports'].some(element => element === this.$router.currentRoute._value.name);
        },
        userCanChangeSchool() {
            if (!this.$user) return false;
            return true;
        },
        getChangeModeClass() {
            return (this.userCanChangeSchool()) ?
                'current-school-and-grade':
                'multi-school-no-swap'
        },
        getSchool() {
            if (!this.$user) return '';
            return this.store.state.schools.find(school => school.key === this.store.state.chosenSchool)?.displayName || '';
        },
        getGrade() {
            if (!this.$user) return '';
            return this.store.state.showingGradeLevel;
        },
        getMarkingPeriod() {
            if (!this.$user) return '';
            return this.store.state.currentQuarter === 'EOY' ? this.store.state.currentQuarter : 'Q' + this.store.state.currentQuarter;
        },
        getRoutes() {
            return routes
                .filter(route => route.meta.grades.includes(this.store.state.showingGradeLevel))
                .filter(route => route.name !== this.$router.currentRoute._value.name)
                .filter(route => route.meta.roles.some(role => this.$user.roles.includes(role)))
                .filter(route => 'featureFlag' in route.meta ? route.meta.featureFlag : true);
        },
        getUserName() {
            return this.$user?.displayName || '';
        },
        getInitials() {
            return this.$user?.initials || '';
        },
        trainingsExist() {
            const myKey = this.$router.currentRoute?._value.meta.key;
            if (!myKey) return false;
            return trainings.filter(training => training.view === myKey).length;
        },
        showLoginAnalytics() {
            this.$router.push('login-reports');
        }
    }
}
</script>
