/**
    This modal allows the user to change grade or school.
*/
<template>
    <Modal data-cy="change-mode-modal" ref="changeMode" title="Change Mode" instructions="" @success="updateMode">
        <div v-if="userCanChangeSchool()">
            <p>School</p>
            <CustomSelect data-cy="school-setting" :options="availableSchools" v-model="school" />
        </div>
        <div>
            <p>Grade</p>
            <CustomSelect data-cy="grade-setting" :options="availableGrades" v-model="grade" />
        </div>
    </Modal>
</template>

<script>
import CustomSelect from '@/components/customSelect.vue';
import Modal from '@/components/modals/modal.vue';
import { compareSchools } from '@/functions/utils.js';

export default {
    data() {
        return {
            school: '',
            grade: '',
            defaultSchool: '',
            availableSchools: [],
            availableGrades: [],
        }
    },
    components: { CustomSelect, Modal },
    methods: {
        setEventListeners() {
            this.ee.on('launchChangeMode', () => this.launchChangeMode());
        },
        getSchool() {
            if (!this.$user) return '';
            return this.store.state.schools.find(school => school.key == this.store.state.chosenSchool)?.displayName || '';
        },
        getGrade() {
            if (!this.$user) return '';
            return this.store.state.showingGradeLevel;
        },
        userCanChangeSchool() {
            if (!this.$user) return false;
            return true;
        },
        launchChangeMode() {
            if (!this.userCanChangeSchool()) return;
            const schools = this.store.state.schools.filter(e => this.$user.schools.includes(e.key));
            // Sort school list alphabetically
            const sortedSchools = schools.sort(compareSchools);
            this.availableSchools = sortedSchools;
            this.availableGrades = this.$user.availableGrades
                .map(e => ({displayName: e.toString()}));
            this.school = this.getSchool();
            this.grade = this.store.state.showingGradeLevel?.toString() || '';
            this.$refs.changeMode.show();
        },
        updateMode() {
            const grade = Number(this.grade);
            const schoolKey = this.store.state.schools.find(e => e.displayName === this.school)?.key;

            if (!schoolKey && !this.config.multiSchool) {
                console.error('School not found');
                return;
            }

            if (grade !== this.store.state.showingGradeLevel || this.school !== this.getSchool()) {
                this.session.resubscribe({grade, schoolKey});
                if (!this.$route.meta.grades.some(grade => grade === this.store.state.showingGradeLevel)) {
                    this.$router.push('/tracker');
                }
            }
        },
    },
    mounted() {
        this.setEventListeners();
    },
}
</script>
