/**
 * Different grade levels have different settings within the app.
 * trackerConfig sets the behavior of the student list view (Tracker.vue)
 * analyticsConfig sets the behavior of the custom charts view (Analytics.vue) as well as any time a user is redirected to the tracker.
 * 
 */

const g9AnalyticsConfig =  {
    rowGroup: 'englishLearner',
    columnGroup: 'gender',
    redirectDefaultFields: [
        '_studentName',
        'gender',
        'raceAndEthnicity.race',
        'englishLearner',
        'specialEducation',
    ]
};

const PSTAnalyticsConfig = {
    rowGroup: 'plans',
    columnGroup: 'gender',
    redirectDefaultFields: [
        '_studentName',
        'counselorName',
        'plans'
    ]
};

//This isn't being used in the Tracker. I don't think this config is up to date.
export const gradeConfig = {
    8: {
        trackerConfig: {
            defaultView: 'G8 Risk & Opportunity',
            defaultInsight: 'G8 Risk & Opportunity'
        },
        analyticsConfig: g9AnalyticsConfig
    },
    9: {
        trackerConfig: {
            defaultView: 'Standard',
            defaultInsight: 'This Quarter On-Track'
        },
        analyticsConfig: g9AnalyticsConfig
    },
    10: {
        trackerConfig: {
            defaultView: 'Standard',
            defaultInsight: 'GPA'
        },
        analyticsConfig: g9AnalyticsConfig
    },
    11: {
        trackerConfig: {
            defaultView: 'Juniors',
            defaultInsight: 'Postsecondary Plan'
        },
        analyticsConfig: PSTAnalyticsConfig
    },
    12: {
        trackerConfig: {
            defaultView: 'Academic Performance',
            defaultInsight: 'Postsecondary Plan'
        },
        analyticsConfig: PSTAnalyticsConfig
    },
};
