/**
    This modal shows available training videos.
*/
<template>
    <Modal
        ref="trainingModal" 
        title="Support" 
        instructions="" 
        :wide="true" 
        :showCancelButton="false"
        data-cy="training-modal"
    >
        <div
            class="training-area"
            data-cy="training-area"
        >
            <div class="training-summary">
                Need a refresher on how to use Data Hub features? <a href="https://drive.google.com/drive/folders/18C7dHnGJ8BDaW_eitEvdRxqY5ZVoyapR" target="_blank" rel="noopener noreferrer">Click here to access our tutorial docs.</a>
            </div>
            <div v-for="(training, index) in getTrainingsToShow()" :key="index">
                <div class="training-title">
                    {{ training.title }}
                </div>
                <div class="training-description">
                    {{ training.description }}
                </div>
                <iframe
                    class="training-video-frame"
                    width="456"
                    height="288"
                    :src="`https://www.youtube.com/embed/${training.link}`"
                    title="YouTube video player"
                    frameborder="0"
                    allowfullscreen>
                </iframe>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@/components/modals/modal.vue';
import { trainings } from '@/apps/trainings.js';

export default {
    components: { Modal },
    methods: {
        show() {
            this.$refs.trainingModal.show();
        },
        getTrainingsToShow() {
            return trainings.filter(training => training.view === this.$router.currentRoute?._value.meta.key);
        }
    },
    mounted() {
        this.ee.on('launchHelp', () => this.show());
    }
}
</script>

<style lang="scss">
    .training-area {
        overflow-y: scroll;    
        max-height: 60vh;
        .training-title {
            font-weight: bold;
            font-size: 2.1rem;
            padding: 10px 0;
        }
        .training-description {
            padding: 10px 0 25px;
        }
        .training-summary {
            padding: 10px 0 25px;
        }
    }
</style>
