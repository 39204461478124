
const schools = [
    {key: 'merryville_shs_2701411', rangeKey: 'schoolRange'},
    {key: 'merryville_sms_2700611', rangeKey: 'schoolRange'},
    {key: 'merryville_willoughby_2701511', rangeKey: 'schoolRange'},
    {key: 'RISE', rangeKey: 'RISERange'},
];

const roles = [
    {
        key: 'viewer',
        schoolRange: {min: 110, max: 120},
        RISERange: {min: 0, max: 0}
    },
    {
        key: 'counselor',
        schoolRange: {min: 25, max: 30},
        RISERange: {min: 0, max: 0}
    },
    {
        key: 'leadCounselor',
        schoolRange: {min: 1, max: 1},
        RISERange: {min: 0, max: 0}
    },
    {
        key: 'schoolAdmin',
        schoolRange: {min: 6, max: 8},
        RISERange: {min: 0, max: 0}
    },
    {
        key: 'OTC',
        schoolRange: {min: 2, max: 2},
        RISERange: {min: 4, max: 5}
    },
    {
        key: 'OTCManager',
        schoolRange: {min: 1, max: 1},
        RISERange: {min: 1, max: 1}
    },
    {
        key: 'riseStaff',
        schoolRange: {min: 0, max: 0},
        RISERange: {min: 28, max: 30}
    },
    {
        key: 'calendarManager',
        schoolRange: {min: 0, max: 0},
        RISERange: {min: 2, max: 2}
    },
    {
        key: 'superuser',
        schoolRange: {min: 0, max: 0},
        RISERange: {min: 7, max: 8}
    },
];

const bands = [
    'noLogins', 'oneOrTwoLogins', 'threeOrMoreLogins'
];

const weekStartingDates = [
    '2022-08-29', '2022-09-05', '2022-09-12', '2022-09-19'
];

const pickFromRange = ({max, min}) => {
    const range = max - min + 1;
    return min + Math.floor(Math.random() * range);
};

const getRandomDataPoint = ({count}) => {
    const percent = pickFromRange({min: 0, max: 50}) / 100;
    return Math.floor(count * percent);
};

const buildEntry = ({range}) => {
    const result = {};
    result.userCount = pickFromRange(range);
    result.noLogins = getRandomDataPoint({count: result.userCount});
    result.oneOrTwoLogins = getRandomDataPoint({count: result.userCount - result.noLogins});
    result.threeOrMoreLogins = result.userCount - result.noLogins - result.oneOrTwoLogins;
    return result;
};

const buildOrgEntry = ({rangeKey}) => {
    const result = {};
    result.total = buildEntry({range: {max: 0, min: 0}});
    roles.forEach(role => {
        result[role.key] = buildEntry({range: role[rangeKey]});
        bands.forEach(band => {
            result.total[band] += result[role.key][band];
        });
        result.total.userCount += result[role.key].userCount;
    });
    return result;
};

const getEmptySummary = () => {
    const result = {};

    roles.forEach(role => {
        result[role.key] = buildEntry({range: {max: 0, min: 0}});
    });
    result.total = buildEntry({range: {max: 0, min: 0}});

    return result;
};

const addToTotal = ({runningTotal, addition}) => {
    const result = runningTotal;

    roles.forEach(role => {
        bands.forEach(band => {
            result[role.key][band] += addition[role.key][band];
        });
        result[role.key].userCount += addition[role.key].userCount;
    });

    bands.forEach(band => {
        result.total[band] += addition.total[band];
    });

    result.total.userCount += addition.total.userCount;

    return result;
};

const buildWeeklyEntry = ({weekStartingDate}) => {
    const result = {
        weekStartingDate,
        orgSummaries: {},
        networkSummary: getEmptySummary()
    };

    schools.forEach(school => {
        result.orgSummaries[school.key] = buildOrgEntry({rangeKey: school.rangeKey});
        result.networkSummary = addToTotal({runningTotal: result.networkSummary, addition: result.orgSummaries[school.key]});
    });

    return result;
};

export const getDummyLoginData = () => weekStartingDates.map(
    weekStartingDate => buildWeeklyEntry({weekStartingDate})
);
