/**
 * Configuration for category fields in the student document that have set options. The key is the data as stored in the document database.
 * The value represents how the data should be displayed.
 */

export const stepOptions = {
    'not started': {displayValue: 'Not started', style: 'warning'},
    'in progress': {displayValue: 'In progress', style: 'info'},
    'completed': {displayValue: 'Completed', style: 'success'}
};

export const stepOrder = ['Not started', 'In progress', 'Completed'];

export const onTrackOptions = {
    'off-track': { displayValue: 'Off-Track', style: 'warning'},
    'almost on-track': { displayValue: 'Almost On-Track', style: 'info'},
    'on-track': { displayValue: 'On-Track', style: 'success'},
};

export const onTrackOrder = ['Off-Track', 'Almost On-Track', 'On-Track'];
