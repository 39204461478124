<template>
  <div v-if="visible" class="notification-banner">
    <p class="notification-msg">{{ message }}</p>
    <button v-if="dismissable" class="close-btn" @click="dismiss">&times;</button>
  </div>
</template>

<script>
export default {
  name: "NotificationBanner",
  props: {
    message: {
      type: String,
      required: true
    },
    dismissable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      visible: true
    }
  },
  methods: {
    dismiss() {
      this.visible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-banner {
  background: var(--color-alert-light);
  padding: 10px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-btn {
  background: none;
  position: absolute;
  right: 18px;
  border: none;
  font-size: 32px;
  &:hover {
      color: var(--color-contrast-medium);
      cursor: pointer;
  }
}

.notification-msg {
  padding-right: 24px;
  text-align: center;
}
</style>
