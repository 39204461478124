/**
 * This contains logic for formatting tabulator cells in the tracker (Tracker.vue).
 */

import { blackOrWhite, getFirst } from '@/functions/utils.js';

export const formatters = {
    nullWarning(colors) {
        return `<span title="No data" style="color: ${colors.contrastLow};" class="material-icons">pending</span>`;
    },
    //TECH DEBT: What is consuming this and why are there so many similar functions that do the same thing? 
    // I don't like the naming convention used here with -ify on everything
    badgify(value, test, colors) {
        if (value === null) return this.nullWarning(colors);
        const color = test ? colors.success : colors.contrastHigh;
        return `<span class="badge" style="color: ${colors.bg}; background-color: ${color};"> ${value} </span>`;
    },
    customStyle(value, options, colors) {
        if (value === null) return this.nullWarning(colors);
        const myOption = options[value];
        if (myOption === undefined) {
            console.error('option not found:', value);
            return ``;
        }
        const color = colors[myOption.style];
        const bgColor = ['info', 'gradeB'].some(style => style == myOption.style) ? colors.contrastFull : colors.bg;
        return `<span class="badge" style="color: ${bgColor}; background-color: ${color};"> ${myOption.displayValue} </span>`;
    },
    bands(value, bands, colors) {
        if (value === null) return this.nullWarning(colors);

        //we need to calculate bands to be inclusive of the high and low values provided.
        const myBand = bands.find(element => element.low <= value && element.high >= value);

        if (myBand === undefined) {
            console.error('band not found:', value);
            return ``;
        }
        const bgColor = colors[myBand.style];
        const color = myBand.textColor ? colors[myBand.textColor] : (['info', 'gradeB'].some(style => style == myBand.style) ? colors.contrastFull : colors.bg);
        
        //TECH DEBT: inline styling is bad practice. this needs reworked with the color classes I added.
        return `<span class="badge" style="color: ${color}; background-color: ${bgColor};">${value}</span>`;
    },
    //want to format ADA bands differently by putting a % label at the end of the percentage.
    ADAbands(value, bands, colors) {
        if (value === null) return this.nullWarning(colors);

        //we need to calculate bands to be inclusive of the high and low values provided.
        const myBand = bands.find(element => element.low <= value && element.high >= value);
        
        if (myBand === undefined) {
            console.error('band not found:', value);
            return ``;
        }
        const color = colors[myBand.style];
        const bgColor = ['info', 'gradeB'].some(style => style == myBand.style) ? colors.contrastFull : colors.bg;

        //TECH DEBT: inline styling is bad practice. this needs reworked with the color classes I added.
        return `<span class="badge" style="color: ${bgColor}; background-color: ${color};">${value}%</span>`;
    },
    trafficLight(value, testArray, colors) {
        if (value === null) return this.nullWarning(colors);

        const passingTest = testArray.find(element => element.test);
        const colorName = passingTest?.colorName || 'contrastHigh';
        const BGColorName = passingTest?.BGcolorName || 'bg';

        return `<span class="badge" style="color: ${colors[BGColorName]}; background-color: ${colors[colorName]};"> ${value} </span>`;
    },
    warnify(value, bool, colors) {
        if (value === null) return this.nullWarning(colors);
        return bool ? `<span style="color: ${colors.warning};" class="material-icons">warning</span>` : '';
    },
    checkify(bool, colors) {
        if (bool === null) return this.nullWarning(colors);
        return bool ? 
            `<span class="material-icons-outlined" style="color: ${colors.success}">task_alt</span>`:
            `<span class="material-icons-outlined" style="color: ${colors.contrastHigher}">circle</span>`;
    },
    bool(bool, trueVal, falseVal, colors) {
        if (bool === null) return this.nullWarning(colors);
        return bool ? trueVal : falseVal;
    },
    stepify(value, options, colors) {
        if (value === null) return this.nullWarning(colors);
        const option = [
            {
                status: 'not started',
                icon: 'circle',
                color: colors.contrastHigher
            },
            {
                status: 'in progress',
                icon: 'pending',
                color: colors.info
            },
            {
                status: 'completed',
                icon: 'task_alt',
                color: colors.success
            },
            {
                status: 'N/A',
                icon: 'task_alt',
                color: colors.contrastHigh
            },
        ].find(element => element.status == value);

        return option ? `<span class="material-icons-outlined" style="color: ${option.color}"> ${option.icon} </span>` : '';
    },
    activate(value, colors) {
        if (value === null) return this.nullWarning(colors);
        const option = [
            {
                status: false,
                icon: 'circle',
                color: colors.contrastHigher
            },
            {
                status: true,
                icon: 'task_alt',
                color: colors.success
            },
        ].find(element => element.status == value);

        return option ? `<span class="material-icons-outlined" style="color: ${option.color}"> ${option.icon} </span>` : '';
    }
};

const formatTagsCell = (cell, tags) => {
    const value = cell.getValue();

    if (!value?.[0]) return '';

    const myTags = tags.filter(tag => value[0].value.includes(tag.docID));

    const htmlContent = myTags
        .map(tag => `<span class="badge" style="background-color: #${tag.color}; color: ${blackOrWhite(tag.color)};">${tag.title}</span>`)
        .join(' ');

    const myTitle = myTags.map(tag => tag.title).join(', ');

    return `<span title="${myTitle}">${htmlContent}</span>`;
};

const formatCellWithVictory = (cell, field, colors) => {
    const victory = field.victory || (() => true);
    const options = field.options || {};
    const bands = field.bands || null;
    return field.formatter({cell, victory, options, bands, colors});
};

export const formatCell = ({ // set formatters for every cell in tracker, taking into account victory conditions
    cell, field, colors, tags
}) => {
    // tags column
    if (field.type === 'tags') return formatTagsCell(cell, tags);

    // cells with formatter set in field.js
    if (typeof field.formatter === 'function') return formatCellWithVictory(cell, field, colors);

    // show warning if value is null
    if (cell.getValue() === null) return formatters.nullWarning(colors);

    // if no formatter is specified, just return original value
    return cell.getValue();
};

export const stepFormatter = ({cell, options, colors}) => {
    const value = getFirst(cell);
    return formatters.stepify(value, options, colors);
};

const getLogValue = ({cell}) => {
    const value = cell.getValue();
    if (value === null) return null;
    if (value === undefined) return null;
    if (value[0]?.value === undefined) return value;
    return value[0].value;
};

export const logFormatter = ({cell, colors}) => { 
    // Formats postsecondary log field with no victory condition
    const result = getLogValue({cell});
    if (result === null) return formatters.nullWarning(colors);
    return result;
};

export const bandsFormatter = ({cell, bands, colors}) => {
    const result = getLogValue({cell});
    if (result === null) return formatters.nullWarning(colors);
    return formatters.bands(result, bands, colors);
};

//want to format ADA bands differently by putting a % label at the end of the percentage.
export const ADAbandsFormatter = ({cell, bands, colors}) => {
    const result = getLogValue({cell});
    if (result === null) return formatters.nullWarning(colors);
    return formatters.ADAbands(result, bands, colors);
};

export const onTrackFormatter = ({cell, options, colors}) => {
    const value = cell.getValue();
    return formatters.customStyle(value, options, colors);
};

export const ADAProfileFormatter = value => 
    ['No data', null].some(element => element === value) ? value : `${value}%`;

export const GPAProfileFormatter = value => {
    if (['No data', null].some(element => element === value)) return 'No data';
    if (typeof value === 'string') return Number(value).toFixed(2);
    return value;
};
